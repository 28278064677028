import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../services/navigation.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class FromPageGuard implements CanActivate {
    constructor(public router: Router, public route: ActivatedRoute, public navController: NavController, public navigationService: NavigationService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        console.log('fromPage guard');
        if ((this.navigationService.lastParams && this.navigationService.lastParams['fromPage']) || this.navigationService.poping) {
            return true;
        } else {
            // this.navController.pop().then(data => {
            //     console.log('pop ok', data);
            // }, error => {
            //     console.log('cannot pop', error);
            const t = this.navigationService.getRootPageUrl();
            console.log('fromPage guard', t);

            if (t) {
                // TODO: not allowed to enter target root. go to parent route?
                this.navController.setDirection('root');
                this.router.navigate([this.navigationService.getRootPageUrl()]);
            }

        }

        return false;
    }
}
