import {Injectable} from '@angular/core';
import {SdkBaseService} from './sdk-base-service';

@Injectable({providedIn: 'root'})
export class SdkScannerService extends SdkBaseService {

    protected getServiceName() {
        return 'ScannerService';
    }

    public scan(callback: Function) {


        if (!this.isAvailable()) { return; }

        this.callNative('scan', {
        }, callback);

    }

}
